<template>
  <q-drawer
    v-model="show"
    elevated
    side="right"
  >
    <div class="column items-stretch">
      <q-stepper
        v-if="!this.$q.platform.is.mobile"
        ref="stepper"
        v-model="currentStep"
        vertical
        header-nav
      >
        <q-step
          name="ondemand-onwardtravel-results"
          :title="$t('onwardtravel.step_choose_result')"
          :header-nav="navigatable.includes('ondemand-onwardtravel-results')"
          :done="navigatable.includes('ondemand-onwardtravel-results')"
        />
        <q-step
          name="ondemand-onwardtravel-details"
          :title="$t('passenger_information')"
          :header-nav="navigatable.includes('ondemand-onwardtravel-details')"
          :done="navigatable.includes('ondemand-onwardtravel-details')"
        />
        <q-step
          name="ondemand-onwardtravel-summary"
          :title="$t('summary')"
          caption="Review and submit"
          :header-nav="navigatable.includes('ondemand-onwardtravel-summary')"
          :done="navigatable.includes('ondemand-onwardtravel-summary')"
        />
        <q-step
          name="ondemand-onwardtravel-payment"
          :title="$t('payment')"
          :header-nav="navigatable.includes('ondemand-onwardtravel-payment')"
          :done="navigatable.includes('ondemand-onwardtravel-payment')"
        />
      </q-stepper>
      <q-list v-if="lastSearch" no-border>
        <q-item-label header>
          {{ $t('journey.info') }}
        </q-item-label>
        <q-item>
          <q-item-section v-if="origin.label.split('.')[2] && destination.label.split('.')[2]">
            <template v-if="origin.context && destination.context">
              <span v-html=" $t('what3words_to_what3words', { origin: origin.label, origin_context: `${$t('near').toLowerCase()} ${origin.context}`, destination: destination.label, destination_context: `${$t('near').toLowerCase()} ${destination.context}` })" />
            </template>
            <template v-else>
              <span v-html=" $t('not3words_to_not3words', { origin: origin.label, destination: destination.label })" />
            </template>
          </q-item-section>
          <q-item-section v-else-if="origin.label.split('.')[2]">
            <template v-if="origin.context">
              <span v-html=" $t('what3words_to_destination', { origin: origin.label, origin_context: `${$t('near').toLowerCase()} ${origin.context}`, destination: destination.label })" />
            </template>
            <template v-else>
              <span v-html=" $t('not3words_to_destination', { origin: origin.label, destination: destination.label })" />
            </template>
          </q-item-section>
          <q-item-section v-else-if="destination.label.split('.')[2]">
            <template v-if="destination.context">
              <span v-html=" $t('origin_to_what3words', { origin: origin.label, destination: destination.label, destination_context: `${$t('near').toLowerCase()} ${destination.context}` })" />
            </template>
            <template v-else>
              <span v-html=" $t('origin_to_not3words', { origin: origin.label, destination: destination.label })" />
            </template>
          </q-item-section>
          <q-item-section v-else v-html=" $t('origin_to_destination', { origin: origin.label, destination: destination.label })" />
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>{{ $t('date.date_time_of_pick_up') }}</q-item-label>
            <q-item-label caption>
              {{ time | timeFilter }}
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>{{ $t('number_of.passengers') }}</q-item-label>
            <q-item-label caption>
              {{ numberOfPassengers }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </q-drawer>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import date from 'utils/date-time'
const { roundMinutesUp, addToDate } = date
import { debounce } from 'lodash'
import { handleErrors } from 'utils/utils'

export default {
  filters: {
    timeFilter (val) {
      return date.toMediumDateTime(val)
    }
  },
  data () {
    return {
      show: !this.$q.platform.is.mobile
    }
  },
  computed: {
    ...mapGetters({
      lastSearch: 'onwardtravel/lastSearch',
      origin: 'onwardtravel/origin',
      destination: 'onwardtravel/destination',
      filters: 'onwardtravel/filters',
      time: 'onwardtravel/time',
      numberOfPassengers: 'onwardtravel/numberOfPassengers',
      searching: 'onwardtravel/searching'
    }),
    minPickup () {
      return roundMinutesUp(addToDate(date.newDate(), { days: 0 }), 5)
    },
    currentStep: {
      get () {
        return this.$route.name
      },
      set (step) {
        this.$router.push({ name: step })
      }
    },
    navigatable () {
      let availablePaths = []
      switch (this.$route.name) {
      case 'ondemand-onwardtravel-confirmation':
        availablePaths.push('ondemand-onwardtravel-payment-card')
      case 'ondemand-onwardtravel-payment-card':
        availablePaths.push('ondemand-onwardtravel-summary')
      case 'ondemand-onwardtravel-summary':
        availablePaths.push('ondemand-onwardtravel-details')
      case 'ondemand-onwardtravel-details':
        availablePaths.push('ondemand-onwardtravel-results')
      case 'ondemand-onwardtravel-results':
        availablePaths.push('ondemand-onwardtravel')
        break
      }
      return availablePaths
    }
  },
  methods: {
    setOrigin (val) {
      this.$store.dispatch('onwardtravel/setOrigin', val)
      this.getResults()
    },
    setDestination (val) {
      this.$store.dispatch('onwardtravel/setDestination', val)
      this.getResults()
    },
    setRequester () {
      this.$store.dispatch('onwardtravel/setRequester', this.userLookup)
      this.getResults()
    },
    setTime (val) {
      this.$store.dispatch('onwardtravel/setValue', { prop: 'time', payload: val })
      this.getResults()
    },
    setPassengers (val) {
      this.$store.dispatch('onwardtravel/setValue', { prop: 'numberOfPassengers', payload: parseInt(val) })
      this.getResults()
    },
    getResults: debounce(function () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.$store.dispatch('onwardtravel/search')
          .catch((err) => {
            handleErrors(err)
          })
      } else {
        this.$q.notify({ color: 'negative', icon: 'clear', message: this.$t('taxi.validation.form') })
      }
    }, 750)
  },
  validations: {
    origin: { required },
    destination: { required },
    time: { required },
    numberOfPassengers: { required }
  }
}
</script>
